import Axios from "@/plugins/Axios";
// import store from "@/store";
import Turma from "@/Models/Turma";

export class TurmasService {
  async syncAll() {
    const response = await Axios().get("turmas");
    const turmas = response.data.map((turma) => new Turma(turma));
    return turmas;
  }

  async syncAllPaginate(pageNumber, query) {
    const response = await Axios().get(`turmas/listall/paginate?page=${pageNumber}&query=${query}`);
    return response.data;
    // return { data: []};
  }

  async syncAllGeral() {
    const response = await Axios().get("turmas/retirarFiltroFranquia");
    const turmas = response.data.map((turma) => new Turma(turma));
    return turmas;
  }

  async visualizarTurma(turma) {
    const response = await Axios().get(`turmas/visualizar/${turma}`);
    const novaTurma = response.data;
    return novaTurma;
  }

  async criarTurma(turma) {
    const response = await Axios().post("turmas", turma);
    const novaTurma = response.data;
    return novaTurma;
  }

  async listarTurmasFranquiaSelect(form) {
    const response = await Axios().post("turmas/listar-turmas-franquia-select", form);
    const turmas = response.data.map((turma) => new Turma(turma));
    return turmas;
  }

  async atualizarTurma(turma) {
    const response = await Axios().put(`turmas/${turma.id}`, turma);
    const turmaAtualizada = response.data;
    return turmaAtualizada;
  }

  async deletarTurma(turma) {
    const response = await Axios().delete(`turmas/${turma.id}`);
    const turmaDeletada = response.data;
    return turmaDeletada;
  }

  async recuperarTurma(turma) {
    return this.atualizarTurma({ ...turma, deleted_at: null });
  }

  // Metricas da turma
  async pegarNotas(turmas) {
    const response = await Axios().post(`turmas/notas/metricas`, turmas);
    return response;
  }

  async pegarMedias(turmas) {
    const response = await Axios().post(`turmas/medias/metricas`, turmas);
    return response;
  }

  async pegarMelhoresAlunos(turmas) {
    const response = await Axios().post(`turmas/melhores-alunos/metricas`, turmas);
    return response;
  }

  async pegarAcompanhamentos(turmas) {
    const response = await Axios().post(`turmas/acompanhamentos/metricas`, turmas);
    return response;
  }

  async mostrarTurma(turma_id) {
    const response = await Axios().get(`turmas/visualizar/${turma_id}/metricas`);
    return response;
  }

  async qtd() {
    const response = await Axios().get("turmas/qtd");
    return response.data;
  }

  async taxaOcupacao() {
    const response = await Axios().get("turmas/taxa-ocupacao");
    return response.data;
  }

  async alunosFrequenciaTurma(turma_id) {
    const response = await Axios().get(`turmas/frequencia/${turma_id}`);
    return new Turma(response.data);
  }

  async registrarPresencaGeral(frequencia, horarios_id) {
    const horariosArray = Array.isArray(horarios_id) ? horarios_id : [horarios_id];
    const response = await Axios().post(`turmas/registrar-presenca-geral`, {
      frequencia,
      horarios_id: horariosArray,
    });
    return response.data;
  }

  async justificarFalta(
    turma_id,
    { observacao = "", file = null, matriculaSelect = null, justificativa_id = null }
  ) {
    const formData = new FormData();
    formData.append("observacao", observacao);
    formData.append("file", file);
    formData.append("matricula_id", matriculaSelect.id);
    formData.append("justificativa_id", justificativa_id);

    Axios().interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });

    const response = await Axios().post(`turmas/${turma_id}/justificar-falta`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async documentoJustificativaFalta(aula_id, matricula_id) {
    const response = await Axios()
      .get(`turmas/falta/baixar-documento/${aula_id}/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `documento_de_justificativa_${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }
}
export default new TurmasService();
