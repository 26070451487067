<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Bimestral
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="primary" outlined>
          <v-card-text>
            <h4 style="color: white">Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4 style="color: white">Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
            <h4 style="color: white">
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12 text-center" class="pt-2">
        <template>
          <v-toolbar>
            <v-tabs dark v-model="form.bimestre" background-color="primary" grow>
              <v-tab
                v-for="item of $constants.bimestres"
                :key="item.value"
                @click="() => editarPlano(item.value ? item.value : 0)"
              >
                <v-badge v-if="form.bimestre === item.value" color="indigo accent-1">
                  {{ item.desc }}
                </v-badge>
                <div v-else>{{ item.desc }}</div>
              </v-tab>
            </v-tabs>
          </v-toolbar>
        </template>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(createOrUpdate)"
        :disabled="submittingForm"
        v-if="loadPlano"
      >
        <v-row>
          <v-col cols="12">
            <div v-if="!form.arquivo_nome">
              <e-label>Arquivo</e-label>
              <v-file-input
                accept="application/pdf"
                ref="arquivo"
                show-size
                truncate-length="60"
                @change="uploadPlanoBimestral($event)"
                @click:clear="onClear"
              />
            </div>
            <v-alert
              v-else
              v-model="alert"
              close-text="Close Alert"
              color="primary"
              dark
              class="d-flex align-start"
              type="success"
            >
              <v-btn
                x-small
                @click="downloadArquivoBimestral(form.id)"
                class="ml-2"
                color="red lighten-5"
                style="margin-right: 17px"
              >
                <v-icon color="red lighten-1" small>fa fa-file-pdf</v-icon>
              </v-btn>
              <v-btn x-small @click="dialog = true" class="ml-2" color="red lighten-5">
                <v-icon color="red lighten-1" small>mdi-delete</v-icon>
              </v-btn>
            </v-alert>

            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Tem certeza que deseja excluir o arquivo?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="dialog = false">Cancelar</v-btn>
                  <v-btn color="red darken-1" text @click="confirmDelete">Excluir</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="2000">
              {{ snackbar.text }}
            </v-snackbar>
          </v-col>
          <!-- <v-col cols="12">
            <ValidationProvider name="Tematica" rules="required" v-slot="{ errors }">
              <e-label>UNIDADES TEMÁTICAS / PRÁTICAS DE LINGUAGEM</e-label>
              <v-textarea solo dense v-model="form.tematica" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
              <e-label>OBJETOS DO CONHECIMENTO</e-label>
              <v-textarea solo dense v-model="form.objeto_conhecimento" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Habilidades" rules="required" v-slot="{ errors }">
              <e-label>HABILIDADES (código alfanumérico BNCC)</e-label>
              <v-textarea solo dense v-model="form.habilidades" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="ABORDAGEM PEDAGÓGICA" rules="required" v-slot="{ errors }">
              <e-label>ABORDAGEM PEDAGÓGICA</e-label>
              <v-textarea solo dense v-model="form.abordagem_pedagogica" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Recursos didaticos" rules="required" v-slot="{ errors }">
              <e-label>RECURSOS DIDÁTICOS</e-label>
              <v-textarea solo dense v-model="form.recursos_didaticos" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="PROCESSOS AVALIATIVOS" rules="required" v-slot="{ errors }">
              <e-label>PROCESSOS AVALIATIVOS</e-label>
              <v-textarea
                solo
                dense
                v-model="form.avaliacao_aprendizagem"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Referencia" rules="required" v-slot="{ errors }">
              <e-label>REFERÊNCIAS</e-label>
              <v-textarea solo dense v-model="form.referencias" :error-messages="errors" />
            </ValidationProvider>
          </v-col> -->
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
            <!-- <v-btn color="success" class="ml-3" @click="dialog = true">Exportar plano</v-btn> -->
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
    <v-row justify="center">
      <!-- <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title class="primary">
            <span style="color: #fff" class="text-h5">Exportar Plano</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(exportarPlano)" :disabled="submittingForm">
                  <v-row>
                    <v-col class="pt-4 pb-0" cols="12">
                      <e-label class="font-weight-medium">Turma</e-label>
                      <p class="font-weight-regular">
                        <v-select
                          :items="turmas"
                          item-text="turma.descricao"
                          item-value="turma.id"
                          return-object
                          label="Selecione o campo abaixo"
                          v-model="turmaSelecionada"
                          @change="(item) => setGestao(item)"
                        ></v-select>
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <e-label class="font-weight-medium"
                        >UNIDADES TEMÁTICAS / PRÁTICAS DE LINGUAGEM</e-label
                      >
                      <p class="font-weight-regular">
                        {{ form.tematica }}
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <e-label class="font-weight-medium">Metodologia</e-label>
                      <p class="font-weight-regular">
                        {{ form.metodologia }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn style="color: #fff" class="red darken-1" @click="dialog = false">
                      Fechar
                    </v-btn>
                    <v-btn
                      :disabled="submittingForm"
                      :loading="submittingForm"
                      type="submit"
                      style="color: #fff"
                      class="green darken-1"
                      >Salvar</v-btn
                    >
                  </v-card-actions>
                </form>
              </ValidationObserver>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog> -->

      <e-modal-confirm
        :confirmDialog="confirmDialog"
        :objectModal="objectModal"
        @changeModalConfirm="enableSubmit"
      >
      </e-modal-confirm>
    </v-row>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },

  mounted() {
    this.loadData();
  },

  data() {
    return {
      arquivosOriginais: {},
      aux: null,
      loadPlano: true,
      enableSubmit: true,
      alert: true,
      confirmDialog: false,
      dialog: false,
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      turmas: [],
      submittingForm: false,
      turmaSelecionada: {
        turma: {
          id: null,
          franquia: {
            descricao: "",
            fantasia: "",
          },
        },
      },
      objectModal: {
        title: "Exportar plano",
        message: "Tem certeza que deseja exportar esta plano?",
      },
      form: {
        bimestre: 0,
        arquivo: null,
        arquivo_nome: null,
      },
      formExportarPlano: {
        bimestre: 0,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      export_gestao: null,
    };
  },
  watch: {
    "form.arquivo": {
      handler(newName) {
        this.form.arquivo = newName ?? "";
      },
      deep: true,
    },
    alert: {
      handler(newName) {
        this.alert = newName;
        if (newName === false) this.deleteArquivoBimestre();
      },
      deep: true,
    },
  },
  methods: {
    confirmDelete() {
      this.alert = false;
      this.dialog = false;
      this.snackbar.text = "Plano excluído com sucesso!";
      this.snackbar.show = true;
    },
    onClear() {
      this.form.arquivo = null;
    },
    async deleteArquivoBimestre() {
      this.form.arquivo_nome = null;
      const deletar = await this.$services.imageService.deletedFile("planos", this.form.id);
    },
    createBase64Pdf(file) {
      return new Promise((resolve, reject) => {
        if (file.type === "application/pdf") {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.form.arquivo = reader.result;
            resolve(reader.result);
          };
          reader.onerror = (error) => reject(error);
        } else {
          reject(new Error("O arquivo não é um PDF."));
        }
      });
    },
    uploadPlanoBimestral(event) {
      this.form = { ...this.form, arquivo_nome: event?.name ?? "" };
      this.alert = true;
      if (event) this.createBase64Pdf(event);
    },

    async downloadArquivoBimestral(plano_id) {
      this.$loaderService.open("Baixando...");
      try {
        await this.$services.planosBimestraisService.downloadArquivoBimestral(plano_id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async editarPlano(bimestre) {
      if (this.form.bimestre !== bimestre) {
        this.arquivosOriginais[this.form.bimestre] = {
          arquivo: this.form.arquivo,
          arquivo_nome: this.form.arquivo_nome,
        };
      }
      this.loadPlano = true;
      this.$loaderService.open("Carregando dados...");
      const refs = this.$refs.arquivo?.reset();
      try {
        const { gestaoDeAulaId } = this.$route.params;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        await this.$services.planosBimestraisService.createOrUpdate(this.form);
        const response = await this.$services.planosBimestraisService.planoBimestral(
          gestaoDeAulaId,
          bimestre
        );
        if (response !== "") {
          this.form = { ...response };
          this.alert = this.form.arquivo_nome !== null;

          if (this.arquivosOriginais[bimestre]) {
            this.form.arquivo = this.arquivosOriginais[bimestre].arquivo;
            this.form.arquivo_nome = this.arquivosOriginais[bimestre].arquivo_nome;
          }
          return;
        }
        this.form = {
          bimestre,
        };
        this.loadPlano = true;
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },

    setGestao(item) {
      this.export_gestao = item.id;
    },

    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { bimestre } = this.form;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.bimestre = bimestre;
        await this.$services.planosBimestraisService.createOrUpdate(this.form);
        this.$toast.success("Plano bimestral salvo com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async exportarPlano() {
      this.$loaderService.open("Exportando plano...");
      try {
        // const { gestaoDeAulaId } = this.$route.params;
        const { bimestre } = this.form;

        this.formExportarPlano.instrutorDisciplinaTurma_id = this.export_gestao;
        this.formExportarPlano.bimestre = bimestre;
        this.formExportarPlano.tematica = this.form.tematica;
        this.formExportarPlano.objeto_conhecimento = this.form.objeto_conhecimento;
        this.formExportarPlano.habilidades = this.form.habilidades;
        this.formExportarPlano.ods = this.form.ods;
        this.formExportarPlano.metodologia = this.form.metodologia;
        this.formExportarPlano.recursos_didaticos = this.form.recursos_didaticos;

        // this.formExportarPlano.id = this.form.id;
        this.formExportarPlano.referencias = this.form.referencias;
        this.formExportarPlano.avaliacao_aprendizagem = this.form.avaliacao_aprendizagem;

        await this.$services.planosBimestraisService.createOrUpdate(this.formExportarPlano);

        this.$toast.success("Plano bimestral exportado com sucesso!");

        this.dialog = false;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async loadData() {
      const { gestaoDeAulaId } = this.$route.params;
      const { bimestre } = this.form;
      try {
        this.$loaderService.open("Carregando dados...");
        this.gestaoDeAula = await this.$services.gestoesDeAulasService.visualizar(gestaoDeAulaId);
        const gestoes = await this.$services.gestoesDeAulasService.listarGestoesProfessor(
          this.gestaoDeAula
        );
        this.turmas = gestoes;

        const response = await this.$services.planosBimestraisService.planoBimestral(
          gestaoDeAulaId,
          bimestre
        );

        if (response !== "") {
          this.form = { ...response };
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
