import Etapa from "@/Models/Etapa";
import Axios from "@/plugins/Axios";
import store from "@/store";

export class EtapasService {
  async syncAll() {
    const response = await Axios().get("etapas");
    const etapas = response.data.map((etapa) => new Etapa(etapa));
    return etapas;
  }

  async single(id) {
    const response = await Axios().get(`etapas/${id}`);
    return response.data;
  }

  async syncAllEtapasDisponiveis(circuitoId, gestaoDeAulaId) {
    const response = await Axios().get(
      `etapas?circuito_nota_id=${circuitoId}&type=dataAtual&gestaoDeAulaId=${gestaoDeAulaId}`
    );
    const etapas = response.data.todas.map((etapa) => new Etapa(etapa));
    return { etapas, disponiveis: response.data.disponiveis };
  }

  async getEtapasByCircuitoNotaId(circuitoId) {
    const response = await Axios().get(`etapas?circuito_nota_id=${circuitoId}`);
    const etapas = response.data.map((etapa) => new Etapa(etapa));
    return etapas;
  }

  async criarEtapa(etapa) {
    const response = await Axios().post("etapas", etapa);
    const novoEtapa = response.data;
    return novoEtapa;
  }

  async atualizarEtapa(circuito) {
    const response = await Axios().put(`etapas/${circuito.id}`, circuito);
    const etapa = response.data;
    return etapa;
  }

  async deletarEtapa(circuito) {
    const response = await Axios().delete(`etapas/${circuito.id}`);
    const etapaDeletada = response.data;
    return etapaDeletada;
  }
}
export default new EtapasService();
