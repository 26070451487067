var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-template',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-h4"},[_vm._v("Turmas")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex mb-2 justify-space-between"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":_vm.criarTurma}},[_vm._v("Criar Turma")]),_c('v-text-field',{staticClass:"ml-2 search-input",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar...","solo":"","autofocus":"","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-col',{staticClass:"pt-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"items":_vm.turmasList,"loading":_vm.carregandoTurmas,"items-per-page":10,"custom-filter":_vm.dataTableFilter,"search":_vm.search},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c("transition-group",{tag:"tbody",attrs:{"name":"scroll-x-transition","duration":"150"}},_vm._l((items),function(turma){return _c('tr',{key:turma.id,class:turma.estaDeletado ? ['deleted'] : [],attrs:{"color":"primary"}},[_c('td',[_vm._v(_vm._s(turma.id))]),_c('td',[_vm._v(_vm._s(turma.codigo))]),_c('td',[_c('v-btn',{attrs:{"text":""},on:{"click":() => _vm.visualizarTurma(turma)}},[_vm._v(" "+_vm._s(turma.descricao)+" ")])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","color":"primary","x-small":""}},'v-chip',attrs,false),on),[_vm._v(" S ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(
                      `Séries: ${turma.series.length} unidades <br>` +
                      turma.series.map((serie) => serie.descricao).join('<br />')
                    )}})]),(!turma.estaDeletado)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(turma.multi)?_c('v-chip',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"label":"","color":"secondary","x-small":""}},'v-chip',attrs,false),on),[_vm._v(" M ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("DISCIPLINA MULTI")])]):_vm._e()],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(turma.matriculas_count)+"/"+_vm._s(turma.qtd))]),_c('td',[(!turma.estaDeletado)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":"","color":"blue-grey lighten-3"},on:{"click":() => _vm.dashboard(turma)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Métricas da turma")])]):_vm._e(),(!turma.estaDeletado)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 white--text",attrs:{"x-small":"","color":"blue","to":{
                        name: 'turmas.frequencia',
                        params: { turma_id: turma.id },
                      }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-thumbs-up ")])],1)]}}],null,true)},[_c('span',[_vm._v("Lançar Frequência")])]):_vm._e(),(!turma.estaDeletado)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":"","color":"blue-grey lighten-3"},on:{"click":() => _vm.editarTurma(turma)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar turma")])]):_vm._e(),(!turma.estaDeletado)?_c('v-tooltip',{attrs:{"color":"error","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":"","color":"error"},on:{"click":() => _vm.deletarTurma(turma)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Apagar turma")])]):_vm._e(),(turma.estaDeletado)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":"","color":"info"},on:{"click":() => _vm.recuperarTurma(turma)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-sync ")])],1)]}}],null,true)},[_c('span',[_vm._v("Recuperar turma")])]):_vm._e()],1)])}),0)]}}])}),_c('e-paginate',{attrs:{"current_page":_vm.paginate.current_page,"last_page":_vm.paginate.last_page,"pageNumber":parseInt(_vm.page_number),"search":_vm.search},on:{"changeActionResponse":_vm.listarTurmas}})],1)],1),_c('e-selecionar-gestao-modal',{attrs:{"dialog":_vm.dialog,"turma_id":_vm.turma_id},on:{"dialogChange":function($event){_vm.dialog = $event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }