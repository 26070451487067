<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Registro de Comportamentos</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="12"
                v-for="(comportamento, index) in comportamentos"
                :key="index"
              >
                <v-textarea
                  v-model="comportamentos[index].descricao"
                  label="Descrição do Comportamento"
                  required
                  rows="3"
                  auto-grow
                  :disabled="comportamentos[index].registered && isProfessor"
                ></v-textarea>
                <v-btn color="success" @click="excluirCampo(index, comportamento.id)">
                  Excluir Comportamento
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="success" @click="adicionarCampo">Adicionar Comportamento</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Fechar</v-btn>
          <v-btn color="blue darken-1" text @click="registrarComportamento(matricula_id)"
            >Registrar Comportamento</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="2000" color="success">
      Comportamentos registrados com sucesso!
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    matricula_id: {
      type: Number,
    },
    value: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: [Number, String],
      required: true,
    },
  },
  async mounted() {
    await this.listarComportamentos(this.matricula_id);
  },
  computed: {
    aulaId() {
      return this.aula_id;
    },
    ...mapGetters("Auth", ["user"]),
    isProfessor() {
      return this.user.roles.some((role) => role.id === "2");
    },
  },
  data() {
    return {
      snackbar: false,
      presenca: {
        matricula: "",
      },
      dialog: this.value,
      comportamento: "",
      comportamentos: [],
      matricula: this.matricula,
    };
  },
  watch: {
    value(newVal) {
      if (newVal !== this.dialog) {
        this.dialog = newVal;
      }
    },
  },
  methods: {
    async registrarComportamento(matricula_id) {
      try {
        this.$loaderService.open("Registrando Comportamento...");
        await this.$services.aulasService.registrarComportamento(
          this.matricula_id,
          this.comportamentos,
          this.aula_id
        );
        await this.listarComportamentos(matricula_id);
        this.$loaderService.close();
        this.snackbar = true;
        this.comportamentos.forEach((comportamento) => {
          comportamento.registered = true;
        });
        this.close();
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
    async listarComportamentos(matricula_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        this.comportamentos = await this.$services.aulasService.getComportamentos(
          matricula_id,
          this.aula_id
        );
        this.comportamentos.forEach((comportamento) => {
          comportamento.registered = true; // Supondo que comportamentos retornados já estão registrados
        });
        console.log(this.comportamentos);
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    close() {
      this.dialog = false;
      this.$emit("close");
    },
    adicionarCampo() {
      this.comportamentos.push({ descricao: "", registered: false });
    },
    async excluirCampo(index, comportamento_id) {
      try {
        if (comportamento_id) {
          this.$loaderService.open("Excluindo Comportamento...");
          await this.$services.aulasService.excluirComportamento(comportamento_id);
        }
        this.comportamentos.splice(index, 1);
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    isAddButtonDisabled() {
      const comportamentosLancados = this.comportamentos.some((comportamento) => {
        return comportamento.user_id === this.user.id;
      });
      return this.isProfessor && comportamentosLancados;
    },
  },
};
</script>
