<template>
  <div>
    <h4 class="mb-3">Configurações das Opções de Notas em Texto</h4>
    <div style="font-size: 12px">
      Aqui você pode cadastrar as opções de notas em texto que o professor vai selecionar no momento
      que for lançar as notas. Por exemplo: B - Bom, R - Reprovado
    </div>
    <div class="row mt-4">
      <v-chip
        @click="remover(variavel.id)"
        @mouseout="handleMouseOut(variavel.id)"
        @mouseover="handleMouseOver(variavel.id)"
        v-for="variavel of optionsNotaTexto"
        :key="variavel.id"
        class="ma-2"
      >
        <div>{{ variavel.descricao }}</div>
        <v-badge
          v-show="chipMouseId === variavel.id"
          bordered
          color="error"
          icon="close"
          class="ml-5"
          overlap
        >
        </v-badge>
      </v-chip>
      <v-btn @click="dialog = true" class="mx-2" small fab dark color="secondary">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </div>

    <div v-show="inputValue.length > 0" class="row mt-4">
      <v-btn @click="salvarVariaveis" class="mx-2" color="primary"> Salvar </v-btn>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Cadastrar Conceito</span>
          </v-card-title>
          <v-card-text>
            <v-col class="pt-5 pb-0">
              <ValidationProvider name="nome" rules="required" v-slot="{ errors }">
                <e-label>Nome</e-label>
                <v-text-field :error-messages="errors" v-model="conceitoNome" dense solo />
              </ValidationProvider>
            </v-col>
            <v-col class="pt-5 pb-0">
              <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
                <e-label>Descrição</e-label>
                <v-textarea :error-messages="errors" v-model="conceitoDescricao" dense solo />
              </ValidationProvider>
            </v-col>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialog = false"> Cancelar </v-btn>
            <v-btn color="green" text @click="salvarVariaveis"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogRemover" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-p">Tem Certeza que deseja remover?</span>
          </v-card-title>
          <v-card-actions>
            <v-btn color="success" @click="removerConfirma">Sim</v-btn>
            <v-btn color="error" @click="dialogRemover = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "configuracoes-variaveis",
  props: {
    circuitoId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    inputValue(value) {},
  },
  data() {
    return {
      dialogRemover: false,
      conceitoNome: null,
      conceitoDescricao: null,
      dialog: false,
      variaveis: [{}],
      inputValue: [],
      optionsNotaTexto: [],
      chipMouseId: null,
      idVariavel: null,
    };
  },
  mounted() {
    this.getOptionsSelect();
  },
  methods: {
    async removerConfirma() {
      const circuito = await this.$services.circuitoNotasService.removerTextoNota(this.idVariavel);
      this.dialogRemover = false;
      this.getOptionsSelect();
    },

    async remover(id) {
      this.dialogRemover = true;
      this.idVariavel = id;
    },

    adicionarVariavel() {
      const ultimaVariavel = this.variaveis[this.variaveis.length - 1];
      this.variaveis.push({ ...ultimaVariavel });
    },

    async getOptionsSelect() {
      const circuito = await this.$services.circuitoNotasService.getCircuito(this.circuitoId);
      this.optionsNotaTexto = circuito.valores_notas_texto;
    },

    async salvarVariaveis() {
      try {
        const dataForm = {
          descricao: this.conceitoNome,
          descricao_texto: this.conceitoDescricao,
          circuitoId: this.circuitoId,
        };
        const resp = await this.$services.circuitoNotasService.salvarTextNota(dataForm);
        this.$toast.success("Opções salvas com sucesso!");
        this.conceitoNome = null;
        this.conceitoDescricao = null;
        this.dialog = false;
        this.getOptionsSelect();
        this.$emit("atualizar", true);
      } catch (error) {
        this.$handleError(error);
      }
    },

    handleMouseOver(id) {
      this.chipMouseId = id;
    },

    handleMouseOut(id) {
      this.chipMouseId = null;
    },
  },
};
</script>
