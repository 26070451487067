import Axios from "@/plugins/Axios";

export class PlanosBimestraisService {
  async planoBimestral(gestaoDeAulaId, bimestre) {
    const response = await Axios().get(`planos/plano-bimestral/${gestaoDeAulaId}/${bimestre}`);
    return response.data;
  }

  async planoMensal(gestaoDeAulaId, mes) {
    const response = await Axios().get(`planos/plano-mensal/${gestaoDeAulaId}/${mes}`);
    return response.data;
  }

  async planoMensalInfantil(gestaoDeAulaId, mes) {
    const response = await Axios().get(`planos/plano-mensal-infantil/${gestaoDeAulaId}/${mes}`);
    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post("planos/criar-plano-bimestral", {
      plano,
    });
    return response.data;
  }

  async planoMensalCreateOrUpdate(plano) {
    const response = await Axios().post("planos/plano-mensal/create-or-update", {
      plano,
    });
    return response.data;
  }

  async planoMensalInfantilCreateOrUpdate(plano) {
    const response = await Axios().post("planos/plano-mensal-infantil/create-or-update", {
      plano,
    });
    return response.data;
  }

  async planoBimestralInfanil(gestaoDeAulaId, bimestre) {
    const response = await Axios().get(
      `planos/plano-bimestral-infantil/${gestaoDeAulaId}/${bimestre}`
    );
    return response.data;
  }

  async createOrUpdateInfantil(plano) {
    const response = await Axios().post("planos/criar-plano-bimestral-infantil", {
      plano,
    });
    return response.data;
  }

  async downloadArquivoBimestral(plano_id) {
    const response = await Axios()
      .get(`planos/baixar-documento/${plano_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.status === 500) {
          throw new Error("Arquivo não encontrado");
        }
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
        return response;
      });
    return response;
  }

  async downloadRelatorioArquivoBimestral() {
    const response = await Axios()
      .get(`planos/baixar-relatorio-bimestral`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (!response.data) {
          throw new Error("Não há planos lançados.");
        }
        const blob = new Blob([response.data], { type: "application/zip" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `relatorio_bimestral.zip`;
        link.click();
      });
    return response;
  }
}
export default new PlanosBimestraisService();
