import LancarNotasPage from "@/Pages/Notas/LancarNotasPage.vue";
import CircuitoNotasPage from "@/Pages/Notas/CircuitoNotasPage.vue";

export default [
  {
    path: "/gestoes-de-aulas/:gestaoDeAulasId/notas/lancar",
    name: "notas.lancarNotas",
    component: LancarNotasPage,
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulasId/notas/circuito-notas",
    name: "notas.circuitoNotas",
    component: CircuitoNotasPage,
  },
];
