import Axios from "@/plugins/Axios";
import store from "@/store";
import Circuito from "@/Models/Circuito";

export class CircuitoNotasService {
  async importarNotasAntigas(circuitoId) {
    const response = await Axios().get(`/importar-notas-para-circuito/${circuitoId}`);
    const resp = response.data;
    return resp;
  }

  async syncAll() {
    const currentUser = store.getters["Auth/user"];
    const response = await Axios().get(`circuito-notas?ano_id=${currentUser.ano_id}`);
    const circuitos = response.data.map((circuito) => new Circuito(circuito));
    return circuitos;
  }

  async syncAllGeral() {
    const response = await Axios().get("circuito-notas");
    const circuitos = response.data.map((circuito) => new Circuito(circuito));
    return circuitos;
  }

  async importarCircuito(circuito_id) {
    const currentUser = store.getters["Auth/user"];
    const response = await Axios().get(
      `importar-circuito-notas?ano_id=${currentUser.ano_id}&circuito_id=${circuito_id}`
    );
    const circuitos = response.data.map((circuito) => new Circuito(circuito));
    return circuitos;
  }

  async remover(id) {
    const response = await Axios().get("circuito-notas/remover-texto-nota", id);
    const novoCircuito = response.data;
    return novoCircuito;
  }

  async criarCircuito(circuito) {
    const response = await Axios().post("circuito-notas", circuito);
    const novoCircuito = response.data;
    return novoCircuito;
  }

  async salvarTextNota(dados) {
    const response = await Axios().post("circuito-notas/salvar-texto-nota", dados);
    const resp = response.data;
    return resp;
  }

  async atualizarCircuito(circuito) {
    const response = await Axios().put(`circuito-notas/${circuito.id}`, circuito);
    const circuitoCircuito = response.data;
    return circuitoCircuito;
  }

  async deletarCircuito(circuito) {
    const response = await Axios().delete(`circuito-notas/${circuito.id}`);
    const circuitoDeletada = response.data;
    return circuitoDeletada;
  }

  async getCircuito(circuitoId) {
    const response = await Axios().get(`circuito-notas/${circuitoId}`);
    const result = response.data;
    return result;
  }

  async removerTextoNota(id) {
    const response = await Axios().get(`circuito-notas/remover-texto-nota/${id}`);
    const result = response.data;
    return result;
  }

  async saveLayoutBoletim(dados) {
    const response = await Axios().post(`save-layout-boletim`, dados);
    const result = response.data;
    return result;
  }

  async getLayoutBoletim(idCircuito) {
    const response = await Axios().get(`get-layout-boletim/${idCircuito}`);
    const result = response.data;
    return result;
  }
}
export default new CircuitoNotasService();
