import HistoricoIndex from "@/Pages/Historico/index.vue";
import NewEditHistoricoPage from "@/Pages/Historico/NewEditHistoricoPage.vue";

export default [
  {
    path: "/historico/:aluno_id",
    name: "historico.index",
    component: HistoricoIndex,
  },
  {
    path: "/historico/create/:aluno_id",
    name: "historico.create",
    component: NewEditHistoricoPage,
    props: { editing: false },
  },
  {
    path: "/historico/edit/:historico_id",
    name: "historico.edit",
    component: NewEditHistoricoPage,
    props: { editing: true },
  },
];
