<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'turmas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Lançando Frequências Geral
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="turma">
        <div>
          <v-card color="primary" class="mb-5" outlined>
            <v-card-subtitle class="font-weight-medium" style="color: white">
              <div>Turma: {{ turma?.turma?.descricao }}</div>
              <div>
                Esta operação de registro de presença será efetuada na data: {{ dataAtual }}.
              </div>
            </v-card-subtitle>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>Funcionalidade da Frequência</v-expansion-panel-header>
                <v-expansion-panel-content>
                  Na opção 'Selecione um horário', apenas os horários em que aulas estão agendadas
                  na data {{ dataAtual }} são exibidos, caso contrário o campo estiver 'Não há dados
                  disponíveis' significa que não existe aula criada ainda. Caso nenhum horário seja
                  selecionado, a frequência será automaticamente registrada para todas as aulas
                  agendadas, ou seja, para o conjunto geral de aulas.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-col class="pt-0 pb-0" cols="4">
      <e-label>Horários</e-label>
      <v-select
        v-model="horarios_id"
        :items="turma?.aulasComHorarioDisciplina"
        :return-object="false"
        :item-text="(item) => item.horario + ' (' + item.disciplina + ')'"
        :item-value="(item) => item.id"
        dense
        solo
        label="Selecione um horário"
        multiple
      >
      </v-select>
    </v-col>
    <v-row v-if="turma">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Aluno</th>
                <th class="text-left">Presença</th>
                <th class="text-left">Horário/Disciplina</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="presenca of presencas"
                :key="presenca.matricula_id"
                :class="
                  $constants.situacoesMatriculaCondition[
                    removerEspaco(presenca.matricula.situacao ? presenca.matricula.situacao : '')
                  ]
                    ? 'red lighten-4'
                    : null
                "
              >
                <td class="font-weight-medium">
                  <div>
                    {{
                      presenca.matricula.aluno
                        ? presenca.matricula.aluno.nomecompleto
                        : `[ALUNO POSSIVELMENTE DELETADO] MATRICULA: ${presenca.matricula.id}`
                    }}
                    <v-chip
                      label
                      color="error"
                      small
                      v-if="
                        $constants.situacoesMatriculaCondition[
                          removerEspaco(
                            presenca.matricula.situacao ? presenca.matricula.situacao : ''
                          )
                        ]
                      "
                      >{{ presenca.matricula.situacao }}</v-chip
                    >
                  </div>
                  <div>
                    <small>
                      {{
                        presenca.matricula.aluno ? presenca.matricula.aluno.codigo_inep : "- - -"
                      }}
                      /
                      {{ presenca.matricula.aluno ? presenca.matricula.aluno.codigo : "- - -" }}
                    </small>
                  </div>
                </td>
                <td>
                  <v-btn-toggle :value="presenca.presente ? 0 : 1" v-if="presenca.matricula.aluno">
                    <v-btn
                      v-if="
                        !$constants.situacoesMatriculaCondition[
                          removerEspaco(
                            presenca.matricula.situacao ? presenca.matricula.situacao : ''
                          )
                        ]
                      "
                      @click="atualizarFrequenciaTurma(presenca, true)"
                      :color="presenca.presente ? 'blue' : 'grey'"
                      :disabled="turma.aulasComHorarioDisciplina.length === 0"
                      small
                      ><v-icon x-small class="white--text">fa fa-thumbs-up</v-icon></v-btn
                    >
                    <v-btn
                      v-if="
                        !$constants.situacoesMatriculaCondition[
                          removerEspaco(
                            presenca.matricula.situacao ? presenca.matricula.situacao : ''
                          )
                        ]
                      "
                      @click="atualizarFrequenciaTurma(presenca, false)"
                      :color="!presenca.presente ? 'red' : 'grey'"
                      :disabled="turma.aulasComHorarioDisciplina.length === 0"
                      small
                      ><v-icon x-small class="white--text">fa fa-thumbs-down</v-icon></v-btn
                    >
                  </v-btn-toggle>
                </td>
                <td>
                  <template>
                    <ul v-if="!presenca.presente">
                      <li v-for="falta in presenca.total_de_disciplinas" :key="falta.id">
                        {{ falta.aula.disciplina.descricao || "" }} /
                        {{ falta.aula.horario.descricao }}
                      </li>
                    </ul>
                  </template>
                </td>
                <td>
                  <v-btn
                    v-if="!presenca.presente"
                    color="success"
                    @click="modalFalta(presenca.matricula, presenca.justificativa_id)"
                    small
                    type="button"
                  >
                    Observação
                  </v-btn>
                  <v-tooltip v-if="!presenca.presente && presenca.document" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        @click="documentoJustificativaFalta(presenca.matricula.id)"
                        class="ml-2"
                        color="red lighten-5"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Baixar documento</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-snackbar
      dense
      :value="exibirSnackbar"
      :timeout="-1"
      class="mb-12"
      :color="snackbarColor"
      right
      bottom
    >
      <div v-if="promisesCount > 0" class="d-flex align-center">
        <v-progress-circular indeterminate size="25" color="white"></v-progress-circular>
        <span class="ml-2 font-weight-medium">Salvando dados de frequência no servidor</span>
        <v-btn @click="fecharSnackbar" class="ml-2" icon><v-icon small>fa fa-close</v-icon></v-btn>
      </div>
      <div v-else-if="errorMessages.length == 0" class="d-flex align-center">
        <v-icon size="20" color="white">fa fa-check</v-icon>
        <span class="ml-2 font-weight-medium">Dados de frequência salvos no servidor!</span>
        <v-btn @click="fecharSnackbar" class="ml-2" icon><v-icon small>fa fa-close</v-icon></v-btn>
      </div>
      <div v-else class="d-flex align-center">
        <v-icon size="20" color="white">fa fa-exclamation-circle</v-icon>
        <span class="ml-2 font-weight-medium">Erro ao salvar dados de frequência!</span>
        <v-btn @click="fecharSnackbar" class="ml-2" icon><v-icon small>fa fa-close</v-icon></v-btn>
      </div>
    </v-snackbar>
    <e-modal-dinamic>
      <ValidationObserver>
        <form :disabled="submittingForm">
          <e-autocomplete
            :items="justificativas"
            :return-object="false"
            :item-value="(value) => value.id"
            :item-text="(value) => value.descricao"
            v-model="form.justificativa_id"
            label="Selecione uma opção"
          />
          <v-file-input v-model="form.file" show-size label="Anexe um arquivo"></v-file-input>
        </form>
      </ValidationObserver>
    </e-modal-dinamic>
  </main-template>
</template>
<script>
export default {
  props: {
    turma_id: {
      type: [String, Number],
      required: true,
    },
  },
  mounted() {
    this.loadBasicData();
    const now = new Date();

    const dia = String(now.getDate()).padStart(2, "0");
    const mes = String(now.getMonth() + 1).padStart(2, "0");
    const ano = now.getFullYear();

    this.dataAtual = `${dia}/${mes}/${ano}`;
  },
  data() {
    return {
      submittingForm: false,
      turma: null,
      presencas: [],
      exibirSnackbar: false,
      promisesCount: 0,
      errorMessages: [],
      justificativas: [],
      form: {
        observacao: "",
        file: null,
        matriculaSelect: {},
        justificativa_id: 0,
      },
      matriculaSelect: {},
      dataAtual: "",
      horariosList: [],
      horarios_id: [],
      aula: [],
      faltasTotal: [],
    };
  },
  computed: {
    snackbarColor() {
      if (this.errorMessages.length > 0) return "error";
      return this.promisesCount > 0 ? "info" : "success";
    },
  },
  methods: {
    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      this.horariosList = await this.$services.horariosService.syncAll();
      const response = await this.$services.aulasService.justificativas();
      this.justificativas = response.justificativas;

      const turma = await this.$services.turmasService.alunosFrequenciaTurma(this.turma_id);
      this.turma = turma;

      if (Array.isArray(this.turma.aulasComHorarioDisciplina)) {
        this.turma.aulasComHorarioDisciplina.slice().sort((a, b) => a.id - b.id);
      } else {
        console.error("turma.aulasComHorarioDisciplina não é uma matriz.");
      }

      this.presencas = await this.turma.turma.matriculas.map((matricula) => {
        const faltou =
          this.turma.faltas.filter(
            (falta) => parseInt(falta.matricula_id, 10) === parseInt(matricula.id, 10)
          ).length > 0;
        const falta = this.turma.faltas.filter(
          (falta) => parseInt(falta.matricula_id, 10) === parseInt(matricula.id, 10)
        );
        return {
          matricula,
          matricula_id: matricula.id,
          presente: !faltou,
          document: falta[0] ? falta[0].document : null,
          justificativa_id: falta[0] ? falta[0].justificativa_id : null,
        };
      });

      this.faltas = await this.turma.faltas;

      this.presencas = await this.presencas.map((matricula) => {
        return {
          ...matricula,
          total_de_disciplinas: this.faltasPorMatricula(matricula.matricula.id),
        };
      });
      this.$loaderService.close();
    },

    faltasPorMatricula(matriculaId) {
      this.faltasTotal = this.faltas.filter((falta) => falta.matricula_id === matriculaId);
      return this.faltasTotal;
    },

    obterDescricaoAula(aulaId) {
      const aula = this.turma.aulasComHorarioDisciplina.find(
        (aula) => String(aula.aula_id) === String(aulaId)
      );
      return aula ? `${aula.disciplina} - ${aula.horario}` : "";
    },

    async atualizarFrequenciaTurma(frequencia, novoEstado) {
      frequencia.presente = novoEstado;
      await this.$services.turmasService.registrarPresencaGeral(frequencia, this.horarios_id);
      await this.loadBasicData();
    },

    async modalFalta(matricula, justificativa_id) {
      this.form.matriculaSelect = matricula;
      this.form.justificativa_id = parseInt(justificativa_id, 10);
      this.$modalsDinamic.info({
        title: "Justificar falta",
        label: `Observação`,
        fileinput: "Anexe um arquivo",
        visibleIcon: true,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modalsDinamic.close();
            },
            props: {
              color: "warning",
              small: true,
              text: true,
            },
          },
          {
            text: "Confirmar",
            callback: async () => {
              try {
                await this.$services.turmasService.justificarFalta(this.turma_id, this.form);
                this.$modalsDinamic.setOptions({ loading: true });
                this.$modalsDinamic.close();
                this.$toast.success("Arquivo anexado com sucesso!");
                await this.loadBasicData();
              } catch (error) {
                this.$handleError(error);
              }
            },
            props: {
              color: "success",
              small: true,
            },
          },
        ],
      });
    },
    fecharSnackbar() {
      this.exibirSnackbar = false;
      this.errorMessages = [];
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
    async documentoJustificativaFalta(matricula_id) {
      this.$loaderService.open("Baixando...");
      try {
        await this.$services.turmasService.documentoJustificativaFalta(
          parseInt(this.turma.turma.id, 10),
          parseInt(matricula_id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style scoped></style>
