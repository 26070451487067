<template>
  <div>
    <v-card class="mx-auto my-1 col-12">
      <v-card-text>
        <div
          style="
            outline: none;
            background-color: rgb(76, 75, 75);
            color: rgb(4, 207, 122);
            padding: 20px;
            border-radius: 10px;
          "
          contenteditable="true"
          ref="editor"
          @input="updateText"
        ></div>
      </v-card-text>
    </v-card>
    <e-expressao-dividers
      type="subetapa"
      :dados="subetapas"
      label="Referência das Subetapas"
      @printarSheet="($event) => printar($event)"
    ></e-expressao-dividers>

    <v-btn class="mt-5" color="primary" dark @click="salvarCondicoesDetalhadas">
      Salvar Regra
    </v-btn>
  </div>
</template>

<script>
import EExpressaoDividers from "./EExpressaoDividers.vue";

export default {
  components: { EExpressaoDividers },
  props: {
    subetapas: {
      type: Array,
    },
    etapaId: {
      type: Number,
    },
  },
  data() {
    return {
      text: "if() <br> {<br><br>}",
      menu: false,
      menu2: false,
      menu3: false,
      textColor: "#000000",
      textoSemTagsHTML: "",
    };
  },
  watch: {
    text(novoValor, valorAntigo) {
      this.$emit("conteudo", novoValor);
    },
  },
  methods: {
    updateText(event) {
      this.text = event.target.innerHTML;
      this.textoSemTagsHTML = this.$refs.editor.innerText;
    },
    executeCommand(command) {
      document.execCommand(command, false, null);
    },

    changeFont(size) {
      document.execCommand("fontSize", false, "7");
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const range = sel.getRangeAt(0);
        const node = document.createElement("span");
        node.style.fontSize = size;
        range.surroundContents(node);
      }
    },
    changeStyle(style) {
      document.execCommand(style, false, null);
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const range = sel.getRangeAt(0);
        const node = document.createElement("span");
        node.style.fontStyle = style === "italic" ? style : null;
        node.style.fontWeight = style === "bold" ? style : null;
        node.style.textDecoration = style === "underline" ? style : null;
        range.surroundContents(node);
      }
    },
    printar(variavel) {
      this.text += `subetapaId[${variavel.id}]`;
      this.$refs.editor.innerHTML += `subetapaId[${variavel.id}]`;
      this.textoSemTagsHTML = this.$refs.editor.innerText;
    },
    setTextColor() {
      this.executeCommand("foreColor", this.textColor);
    },
    async salvarCondicoesDetalhadas() {
      try {
        const response = await this.$services.regrasService.criarRegraDetalhada({
          etapa_id: this.etapaId,
          regra: this.textoSemTagsHTML,
        });
        this.$emit("fecharCondicDetalhada", true);
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
  mounted() {
    this.$refs.editor.innerHTML = this.text;
    this.$refs.editor.focus();
  },
  beforeDestroy() {
    this.text = this.$refs.editor.innerHTML;
  },
};
</script>
